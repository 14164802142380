import { type MaybeRefOrGetter, toRaw, toValue } from 'vue'

import type { PaymentMethodConfig } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import {
  createSharedComposable,
  promiseTimeout,
  useScriptTag,
} from '@vueuse/core'

import type {
  GooglePay,
  GooglePayIsReadyToPayRequest,
  GooglePayPaymentDataRequest,
  GooglePayPaymentsClient,
  GooglePayTransactionInfo,
} from '../../../../types/google-pay'
import { getDuration } from '../../../../utils/performance/getDuration'
import { PaymentError } from '../../form-common'
import { isValidGooglePayPaymentMethodConfig } from '../../form-common/helpers/isValidConfig'
import { PaymentMethodMisconfiguredError } from '../../form-common/types/PaymentMethodMisconfiguredError'

const GOOGLE_PAY_LIBRARY_URL = 'https://pay.google.com/gp/p/js/pay.js'
const GOOGLE_PAY_READY_TIMEOUT = 800

const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0,
}

function useDurationLogger(logger: ReturnType<typeof useLogger>) {
  function log(key: string, state: 'start'): void
  function log(key: string, state: 'success' | 'error', message?: string): void
  function log(
    key: string,
    state: 'start' | 'success' | 'error',
    message?: string,
  ) {
    if (state === 'start') {
      getDuration(`GooglePay_${key}_${state}`)

      return
    }

    logger.info(`[PAYMENT] [GooglePay] ${message ?? `${key} ${state}`}`, {
      duration: getDuration(`GooglePay_${key}_${state}`),
      method: 'GooglePay',
      key,
      state,
    })
  }

  return log
}

function useGooglePayScript(
  log: ReturnType<typeof useDurationLogger>,
): Promise<GooglePay> {
  log('library', 'start')

  return new Promise((resolve) => {
    useScriptTag(GOOGLE_PAY_LIBRARY_URL, () => {
      log('library', 'success')
      resolve(window.google)
    })
  })
}

export const useGooglePay = createSharedComposable(
  (config: MaybeRefOrGetter<PaymentMethodConfig>) => {
    const logger = useLogger()
    const log = useDurationLogger(logger)
    const googlePayScript = useGooglePayScript(log)

    function rawConfig() {
      const raw = toRaw(toValue(config))

      if (isValidGooglePayPaymentMethodConfig(raw)) {
        return raw
      }

      throw new PaymentMethodMisconfiguredError(
        'Invalid Google Pay configuration',
      )
    }

    let paymentsClient: GooglePayPaymentsClient
    async function getPaymentsClient() {
      if (!paymentsClient) {
        const { environment } = rawConfig()
        const library = await googlePayScript

        paymentsClient = new library.payments.api.PaymentsClient({
          environment,
        })
      }

      return paymentsClient
    }

    function getIsReadyToPayRequest(): GooglePayIsReadyToPayRequest {
      const { type, parameters } = rawConfig()

      return {
        ...BASE_REQUEST,
        allowedPaymentMethods: [{ type, parameters }],
        existingPaymentMethodRequired: true,
      }
    }

    function getPaymentDataRequest(
      transactionInfo: GooglePayTransactionInfo,
    ): GooglePayPaymentDataRequest {
      const { type, parameters, tokenizationSpecification, merchantInfo } =
        rawConfig()

      return {
        ...BASE_REQUEST,
        transactionInfo,
        merchantInfo,
        allowedPaymentMethods: [
          { type, parameters, tokenizationSpecification },
        ],
      }
    }

    async function isReadyToPay() {
      const client = await getPaymentsClient()

      log('isReadyToPay', 'start')

      const request = getIsReadyToPayRequest()
      const { result } = await client.isReadyToPay(request)

      if (!result) {
        log('isReadyToPay', 'error')
        throw new PaymentError('Google Pay is not ready to pay', {
          type: '/errors/payment/unexpected/google-pay-not-ready',
        })
      }

      log('isReadyToPay', 'success')
    }

    async function isReady(useTimeout = true) {
      try {
        log('isReady', 'start')

        const promises: Promise<void>[] = [isReadyToPay()]

        if (useTimeout) {
          promises.push(
            promiseTimeout(GOOGLE_PAY_READY_TIMEOUT).then(() => {
              throw new PaymentError('Google Pay isReady timeout', {
                type: '/errors/payment/unexpected/google-pay-is-ready-timeout',
              })
            }),
          )
        }

        await Promise.race(promises)

        log('isReady', 'success')
      } catch (err) {
        log('isReady', 'error')
        throw err
      }
    }

    return {
      isReady,
      getPaymentsClient,
      getIsReadyToPayRequest,
      getPaymentDataRequest,
    }
  },
)

export type UseGooglePay = ReturnType<typeof useGooglePay>
